/**
 * @desc Custom javascript required to handle simple DOM manipulation (using jQuery).
 */
const $ = require('jquery/dist/jquery');

window.getParams = function (url) {
  var params = {};
  var parser = document.createElement('a');
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

// Refresh all floated fields
window.hideUI = function() {
  $('.navbar').addClass('navbar-fullscreen');
  $('.footer').addClass('footer-fullscreen');
  $('.container-collections').addClass('main-fullscreen');
  $('.cat-list').addClass('list-fullscreen-vmode');
  $('.stat-tools').removeClass('menu-visible');
  $('.stats-container').removeClass('menu-visible');
};

window.showUI = function() {
  $('.navbar').removeClass('navbar-fullscreen');
  $('.footer').removeClass('footer-fullscreen');
  $('.container-collections').removeClass('main-fullscreen');
  //$('.container-categories').removeClass('main-fullscreen');
  //$('.container-content').removeClass('main-fullscreen');
  $('.cat-list').removeClass('list-fullscreen-vmode');
  $('.stat-tools').addClass('menu-visible');
  $('.stats-container').addClass('menu-visible');
};

window.hideMinimapFrame = function() {
  $("#minimap-resizable").css('opacity', '0');
}

window.showMinimapFrame = function() {
  $("#minimap-resizable").css('opacity', '1');
}

window.lock_slide = false;

/**
 * @desc Handle floating label input classes.
 */

// Refresh all floated fields
function focusFloat() {
  setTimeout(function(){ 
    $('.floating-field-input').each(function() {

      //Check if browser is chrome/webkit and value is present, indicating there is autofilled content
      try {
        var hasValue = $('#'+$(this).attr('id')+':-webkit-autofill').length > 0;
      }
      catch(err) {
        var hasValue = false;
      }
      //If not, check if value is present in regular way
      if (!hasValue){
        hasValue = $(this).val().length > 0;//Normal
      }

      if(hasValue){
        $(this).next().addClass('minimise-label');
      }
    });
  }, 100);

  setTimeout(function(){ 
    $('.floating-field-input').not($(document.activeElement)).each(function() {

      //Check if browser is chrome/webkit and value is present, indicating there is autofilled content
      try {
        var hasValue = $('#'+$(this).attr('id')+':-webkit-autofill').length > 0;
      }
      catch(err) {
        var hasValue = false;
      }
      //If not, check if value is present in regular way
      if (!hasValue){
        hasValue = $(this).val().length > 0;//Normal
      }

      if(!hasValue){
        $(this).next().removeClass('minimise-label');
      }
    });
  }, 100);
  
}

$('.floating-field-input').focusin(function() {
    //focusFloat();
    $(this).next().addClass('minimise-label');
  });

$('.floating-field-input').focusout(function() {
    focusFloat();
  });

$('.floating-field-input').on('input', function() {
  focusFloat();
});
focusFloat();




//Remove hover events for touch capable devices

function hasTouch() {
  return 'ontouchstart' in document.documentElement
         || navigator.maxTouchPoints > 0
         || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) { // remove all the :hover stylesheets
  try { // prevent exception on browsers not supporting DOM styleSheets properly
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}
$('.event').removeClass("active");
$('.event').on( "touchstart", function(){
  $(this).addClass("active");
});
$('.event').on( "touchmove", function(){
  $(this).removeClass("active");
});




